#root,
#app {
  display: flex;
  flex-grow: 1;
}

.navbar-brand {
  &__title {
    font-size: 12px;
    height: 1rem;
    display: block;
  }
}